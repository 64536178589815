@media (max-width: 1400px) {
  .space {
    flex-flow: column;
    justify-content: flex-end;
    position: relative;
  }

  .sell .space, .commercial.space {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .details .space {
    flex-flow: row;

    h2 {
      padding-right: 1rem;
    }
  }

  .commercial.space {
    justify-content: flex-end;

    div {
      width: 100%;
      order: 2;
    }

    .legend {
      width: auto;
      order: 1;
    }

    &.sold-head {
      justify-content: space-between;

      div {
        width: calc(100% - 400px);
        padding-right: 1rem;

        .symbol {
          width: 100%;
        }

        &.top {
          padding-right: 0;
          width: 400px;
        }
      }
    }
  }
  .search-bar {
    margin: 0 0 1rem auto;
  }

  .content {
    padding: 2rem;
  }

  .legend {
    box-shadow: none;
    background: none;
    padding: 0;
    justify-content: flex-end;
    margin: 0;
    width: 100%;
    flex-flow: row wrap;

    &.big {
      min-width: 1px;
      width: auto;
    }
  }

  .sidebar {
    position: absolute;
    bottom: 0;
    transition: all .3s;
    left: -100%;

    &.hide {
      left: 0;
    }
  }

  .content {
    transition: all .3s;
    margin-left: 0;
    width: 100%;

    &.full {
      width: calc(100% - 280px);
      margin-left: 280px;
    }
  }
  .row {
    width: calc(100% + 2vw);
    margin: 0 -1vw;
  }
  .flex-item {
    padding: 0 1vw;
  }

  .space.broker {
    flex-flow: row;
    justify-content: space-between;

    .show-other {
      margin-bottom: .5rem;
      min-width: 303px;
      margin-left: 1.5rem;
    }

    .search-bar {
      margin: 0 0 1rem;
    }
  }

  .email-text {
    .sold {
      width: 100%;
      order: 2;
    }
  }

  .content.full {
    .sales-content {
      margin: 0;
    }
  }
  .sales-content {
    margin: auto;
  }
}

@media (min-width: 950px) and (max-width: 1300px) {
  .content.full {

    &.details {
      .loader {
        margin: .48rem 0 .48rem auto;

        img {
          width: 40px;
          margin: auto;
        }
      }

      .flex-item {
        height: 100%;
        width: 100%;
      }

      &.sell {
        .flex-item:nth-child(1) {
          .shadow-box:first-child {
            min-height: 1px;
          }

          .shadow-box:last-child {
            min-height: 1px;
          }
        }

        .flex-item:nth-child(2) {
          .shadow-box:first-child {
            min-height: 1px;
          }

          .shadow-box:last-child {
            min-height: 1px;
          }
        }
      }
    }

    .edit-broker {
      .flex-item-33 {
        width: 50%;
      }

      h3 {
        margin: 1rem 0 .5rem;
      }

      .interactions {
        width: 50%;
        padding: .2rem .5rem;
        align-self: flex-end;
        justify-content: flex-end;

        .btn {
          margin: 1rem 0 1rem 1rem;
        }
      }
    }

    .statistic {
      .flex-item {
        width: 100%;
        padding-bottom: 1.5rem;
      }
    }

    .domain, .domain.smaller, .utm.smaller {
      width: 100%;
    }
  }
}

@media (max-width: 1100px) {
  .content.full {

    .lightbox-gallery div {
      width: calc(25% - 1rem);
    }
    .domain {
      width: calc(100% - 140px);
    }

    .utm.smaller {
      width: calc(100% - 140px);
    }

    .flex-item {
      width: 100%;

      .shadow-box {
        min-height: 1px;
      }
    }

    .space.broker {
      flex-flow: row wrap;
      justify-content: flex-end;

      .search-bar {
        width: 100%;
        max-width: none;
      }
    }

    .commercial.space.sold-head div {
      width: 100%;

      &.top {
        margin-top: 1rem;
        max-width: none;

        .search-bar {
          max-width: none;
        }
      }
    }
  }

  .details-info .btn-download {
    min-width: 1px;
    max-width: 229px;
    width: 55%;
  }

  .lightbox-gallery div {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 950px) {
  .legend {
    position: relative;
    width: auto;
    text-align: right;

    &:hover {
      .legend-content {
        opacity: 1;
        z-index: 2;
      }
    }

    .legend-content {
      min-width: 170px;
      text-align: left;
      transition: opacity .4s;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 2rem;
      padding: .4rem .7rem;
      z-index: -2;
      display: block;
      background: #fff;
      box-shadow: $shadow;

      p {
        display: block;
        margin: .4rem 0;
      }
    }
  }

  .show-legend {
    display: inline-block;
    min-width: 120px;
    color: #c5c5c5;
    font-size: .95rem;
    text-align: right;
    cursor: pointer;

    span {
      text-align: center;
      width: 25px;
      height: 25px;
      line-height: 28px;
      background: #c5c5c5;
      color: #fff;
      display: inline-block;
    }
  }

  .search-bar {
    max-width: none;
  }

  header {
    padding: 1rem;
  }

  .menu {
    a {
      margin-left: 1rem;
    }

    span {
      display: none;
    }

    .icon {
      margin: 0;
      font-size: 1.6rem;
    }
  }

  .sidebar {
    overflow: auto;
    z-index: 3;
  }
  .commercial.space {
    justify-content: flex-end;
    margin-bottom: .7rem;

    div {
      width: 100%;
      order: 1;
    }

    .legend {
      margin-top: .5rem;
      order: 2;
    }
  }

  .content.full {
    margin: 0;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      background: rgba(255, 255, 255, 0.75);
    }

    .commercial.space.sold-head {
      justify-content: space-between;

      div {
        width: calc(100% - 400px);

        &.top {
          margin-top: 0;
          width: 400px;
        }
      }
    }

    .sales-content {
      margin: auto;
    }
  }

  .flex.sub {
    flex-flow: row wrap;
  }

  .row {
    width: 100%;
    margin: 0;
  }

  .flex-item {
    width: 100%;
    padding: 0 0 1rem;
  }

  .details {
    .flex-item {
      &:last-child, &:first-child {
        padding: 0;

        .shadow-box {
          min-height: 1px;
        }
      }

    }
  }

  .symbol {
    width: calc(100% - 400px);
  }

  .top {
    max-width: none;
    width: 400px;
  }
  .lightbox-gallery div {
    width: calc(25% - 1rem);
  }

  .modal#invoice {
    .flex-item {
      width: 50%;

      &-35, &-65 {
        width: 100%;
        padding: 0;
      }

      &-65 {
        margin-top: 1rem;

        .document-list {
          max-width: none;
        }
      }
    }

    .document-list {
      max-width: 50%;
    }
  }

  .broker-table .edit-broker {
    .flex-item-33 {
      width: 50%;
    }

    h3 {
      margin: 1rem 0 .5rem;
    }

    .interactions {
      width: 50%;
      padding: .2rem .5rem;
      align-self: flex-end;
      justify-content: flex-end;

      .btn {
        margin: 1rem 0 1rem 1rem;
      }
    }
  }

  .modal#new-broker {
    .headline {
      padding: .5rem;
    }

    .flex-item-33 {
      padding: 0 .5rem;
    }

    input.btn {
      margin-right: .5rem;
    }

    .flex .flex h3 {
      padding: 0 .5rem;
    }
  }

}

@media (max-width: 820px) {
  .symbol {
    width: 100%;
  }
  .top {
    width: 100%;
  }

  .modal#contract .modal-content {
    .flex-item {
      &-35 {
        padding: 0;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 2rem;

        .section {
          width: 50%;

          &:first-child {
            padding-right: .7rem;
          }

          &:last-child {
            padding-left: .7rem;
          }

          .select-selected {
            margin-top: .2rem;
          }
        }
      }

      &-65 {
        padding: 0;
        width: 100%;
      }
    }
  }

  .broker-table .edit-broker {
    td {
      padding: 0;
    }

    .flex-item-33 {
      padding: 0 .5rem;
    }

    .headline {
      padding: .5rem;
    }
  }

  #new-broker {
    .flex-item-33 {
      width: 50%;
    }

    .flex .flex {
      width: 50%;

      .personal {
        width: 100%;

        .flex-item-33 {
          width: 100%;
        }
      }

      .bottom.smaller {
        width: 100%;
      }
    }
  }

  .commercial.space.sold-head div {
    width: 100% !important;
    padding: 0;

    &.top {
      margin-top: 1rem !important;
      max-width: none;
      min-width: 1px;

      .search-bar {
        max-width: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .modal#contract .modal-content .flex-item-35 .section {
    width: 100%;
    padding: 0 !important;

    .sign {
      .btn, p {
        width: 50%;
      }

      .btn {
        text-align: center;
        min-width: 180px;
      }
    }

    .no-sign .btn {
      max-width: 50%;
      min-width: 220px;
    }
  }

  .broker-table .edit-broker {
    .flex-item-33 {
      width: 100%;
      max-width: 550px;
    }

    h3 {
      margin: 1rem 0 .5rem;
    }

    .interactions {
      max-width: 550px;
      width: 100%;
      padding: .2rem .5rem;
      align-self: flex-end;
      justify-content: space-between;

      .btn, .delete {
        margin: .5rem 0 1rem;
      }

      .delete {
        margin-left: .8rem;
      }
    }
  }

  .space.broker {
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .login {
    .left, .right {
      width: 100%;
      margin: 0;
      padding: 2rem;

      h1 {
        max-width: none;
      }
    }

  }
}

@media (max-width: 600px) {
  .logo img {
    width: 160px;
  }
  .burger {
    width: auto;
  }
  .menu {
    width: auto;
  }

  .pages {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
  .pagination {
    width: 100%;
    text-align: center;
  }

  .content {
    padding: 1.5rem;
  }

  .statistic {
    .pagination {
      width: auto;
    }

    .pages {
      width: auto;
    }
  }
  .modal#invoice {
    .document-list {
      max-width: none;
    }

    .flex-item {
      width: 100%;
      padding: 0 !important;

      &-65 .flex {
        .btn {
          margin: 0 0 1rem !important;
        }
      }
    }
  }

  .modal.show {
    display: block;
    padding-bottom: 0;

    .modal-content {
      margin-bottom: 2rem;
    }
  }

  .details .space {
    flex-flow: row wrap;

    h2 {
      padding-right: 0;
      order: 2;
    }

    .btn-border {
      margin: 0 0 0 auto;
    }
  }
  .modal#call .modal-content label {
    width: 100%;

    &:nth-child(1), &:nth-child(2) {
      padding: 0;
    }
  }
  .details-info label, .details-info p, .content.full .details-info label, .content.full .details-info p {
    width: 100%;
    text-align: left;
    margin: 0 0 .5rem;

    &.grey {
      width: 100%;
      margin: 0;
    }
  }
  .details-info .upload, .details .btn-download {
    width: 100%;
  }
  .broker-table .edit-broker .interactions {
    flex-flow: column;
    max-width: 300px;

    .delete {
      text-align: left;
    }
  }

  .modal#new-broker {
    .headline {
      padding: 0;
    }

    .flex-item-33 {
      width: 100%;
      padding: 0;
    }

    .flex .flex {
      width: 100%;

      h3 {
        padding: 0;
      }
    }
  }

  .domain, .domain.smaller, .utm.smaller {
    width: 100%;
  }
  .details .loader {
    margin: .48rem 0 .48rem auto;

    img {
      margin: .5rem auto;
    }
  }

  .sidebar {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    width: 100%;

    .navigation ul {
      margin-bottom: 1.5rem;

      ul {
        margin-bottom: 0;
      }
    }
  }

  .dataTables_info, .dataTables_paginate{
    float: none;
    clear: both;
    text-align: center;
  }

  .lightbox-gallery div {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 400px) {
  header {
    flex-flow: row wrap;
    height: 105px;
  }
  .logo {
    width: 100%;
    text-align: center;
    order: 1;
  }
  .menu {
    width: 50%;
    order: 3;
  }
  .burger {
    width: 50%;
    order: 2;
  }
  .sidebar {
    min-height: calc(100vh - 105px);
  }
  .modal-content {
    padding: 1rem;

    .flex {
      flex-flow: row wrap;

      .btn, .btn.btn-blue {
        margin: .3rem auto;
      }
    }
  }
  .modal {
    padding: 1.25rem;
  }

  .modal#contract .modal-content .flex-item-35 .section {
    .sign {
      flex-flow: row wrap;

      .btn, p {
        width: 100%;
        text-align: center;
      }

      p {
        margin-top: .3rem;
        text-align: right;
      }
    }

    .no-sign .btn {
      max-width: none;
    }
  }

  .btn {
    width: 100%;
    padding: .7rem;

    &-border {
      width: auto;
    }
  }

  .space.broker .show-other {
    min-width: 1px;
    width: 100%;
    margin-left: 0;
    line-height: 1.2rem;
  }

  h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .login {
    .left .logo {
      width: 200px;
      order: unset;
    }
  }
}

@media (max-width: 330px) {
  .modal {
    padding: .5rem;
  }
}

@media (max-height: 750px) {
  .sidebar .salesman {
    position: relative;
    margin-top: 3rem;
    display: block;
  }
}