$blue: #0096c8;
$darkblue: #181F33;
$orange: #EA6413;
$green: #50C595;
$yellow: #F9C032;
$lightblue: #23425B;
$red: #EF4C43;
$grey: #aaa;
$lightgrey: #F2F2F2;
$lines: #E5E5E5;
$shadow: 0 3px 6px rgba(0,0,0,.2);

$green: #7aba48;

$font-medium: url('../font/Montserrat-Medium.ttf');
$font-bold: url('../font/Montserrat-Bold.ttf');

@font-face {
  font-family: Montserrat;
  src: $font-medium;
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: $font-bold;
  font-weight: 700;
}

body, html {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.55rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: $darkblue;
  scrollbar-color: $orange $darkblue;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

header {
  background: $lightgrey;
  padding: .7rem 2rem;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  color: #fff;
  border-bottom: 4px solid $blue;
}

// img {
//   width: 100%;
// }

h2{
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

h3{
  font-weight: bold;
  font-size: 1.15rem;
  line-height: 1.7rem;
}

input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input::-ms-clear {
  display: none;
}

input {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  outline: none;
  padding: .42rem .5rem;
  font-size: inherit;
  font-family: inherit;
  border: 1px solid $lines;

  &::placeholder {
    opacity: 1;
    color: $grey;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background: $blue;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: $orange;
  border-radius: 0;
}

::selection {
  background: $orange;
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $blue;
  -webkit-box-shadow: 0 0 0 1000px #ffe6e3 inset;
  box-shadow: 0 0 0 1000px #ffe6e3 inset;
  transition: background-color 5000s ease-in-out 0s;
}