.modal {
  position: fixed;
  overflow: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  display: none;

  h2 {
    margin-bottom: 1rem;
  }

  .line {
    margin-bottom: 1.5rem;
  }

  input, .textarea {
    padding: .7rem .5rem;
    border: none;
    background: #eee;
    font-size: .9rem;
  }

  .radio input[type=radio] + .radio-label:before {
    border: none;
    background: #eee;
  }

  .radio input[type=radio]:checked + .radio-label:before {
    box-shadow: inset 0 0 0 5px #eee;
  }

  &.show {
    display: flex;
  }

  label {
    line-height: 1.1rem;
    font-size: .85rem;
    display: block;
    margin-bottom: .7rem;
  }

  .radio label {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .custom-select {
    .select-selected {
      background: #eee;
      margin-bottom: 0;
      border: none;
    }

    .select-items {
      background: #fff;
    }
  }

  &#delete .modal-content {
    width: 100%;
    max-width: 570px;

    p {
      max-width: 400px;
    }

    .flex {
      flex-flow: row;

      .btn {
        margin-left: 1vw;
        width: 100%;

        &.btn-blue {
          margin-right: 1vw;
          margin-left: 0;
        }
      }
    }
  }

  &#call {
    .modal-content {
      width: 100%;
      max-width: 700px;

      p {
        margin: 1.5rem 0 0;
        font-size: .85rem;
      }

      label {
        width: 50%;

        &:nth-child(1) {
          padding-right: .5rem;
        }

        &:nth-child(2) {
          padding-left: .5rem;
        }

        input {
          font-size: 1rem;
          height: 40px;
        }


        &.full {
          width: 100%;
          margin-top: 1rem;
        }
      }

      .radio {
        margin-right: 1rem;

        label {
          font-size: 1rem;
          padding: 0;
        }
      }
    }
  }

  &#invoice {
    .modal-content {
      width: 100%;
      max-width: 800px;

      .left {
        margin-right: 1rem;
        border: 1px solid $lines;
        padding: 1rem;
        align-self: flex-start;
        width: calc(50% - 1rem);
      }

      .right {
        width: calc(50% - 1rem);
        margin-left: 1rem;
        position: relative;

        .btn {
          margin: 1rem 0;
        }

        .document-list {
          margin: -.5rem 0 1rem 0;

          .status {
            width: 12px;
            height: 12px;
            margin-right: .4rem;
          }

          .paid {
            .status {
              background: $green;
            }
          }

          .open {
            .status {
              background: $red;
            }
          }

        }
      }

      .radio {
        min-width: 105px;

        label {
          font-size: .9rem;
        }
      }

      p {
        font-size: .85rem;
        line-height: 1.1rem;
        margin: 0;

        &.info {
          margin: .25rem 0 1rem;
        }
      }

      input {
        margin: .2rem 0;

        &.btn {
          margin-top: 1rem;
        }
      }

      h3 {
        margin: 0;
        line-height: 1.5rem;
        font-size: 1rem;
      }

      &.bill {
        max-width: 400px;

        .document-list {
          margin: -.5rem 0 .5rem;

          li, a {
            color: $blue;

            .icon:hover {
              color: $orange;
            }
          }
        }

        .line {
          margin-top: 1.5rem;
        }

        .download {
          position: relative;
          padding-left: 3rem;

          h2 {
            margin: .25rem 0;
          }
        }

        .btn {
          margin: 2rem 0 0;

          &-download {
            position: absolute;
            width: 40px;
            height: 40px;
            padding: .5rem 0;
            font-size: 1.3rem;
            background: $blue;
            margin: 0;
            left: 0;
            top: 0;
            border: none;
            align-self: center;

            &:hover, &:focus {
              color: #fff;
              background: $orange;
            }
          }
        }

        .top {
          width: 100%;
          margin-top: 1rem;
          text-align: left;
        }

        .sign {
          margin: 0 0 1.5rem;
          display: inline-block;

          .btn {
            margin: 0 !important;
          }
        }
      }

      .edit {
        width: 100%;
        margin-top: .5rem;
        border: 1px solid $lines;
        padding: 1rem;
        color: inherit;
        display: none;

        .document-list {
          margin-bottom: .5rem;

          li, a {
            color: $blue;

            .icon:hover {
              color: $orange;
            }
          }
        }

        &.show {
          display: block;
        }


      }

      .waring {
        &.hide {
          display: none;
        }
      }
    }

    .btn {
      padding: .5rem 1rem;
      font-size: .95rem;
    }

  }

  &#contract {

    .modal-content {
      width: 100%;
      max-width: 850px;

      .textarea {
        min-height: 172px;
      }

      .right {
        margin-right: 1rem;
      }

      .btn {
        margin: 1rem 0 0 auto;
      }

      .flex-item {
        &-35 {
          width: 300px;
        }

        &-65 {
          width: calc(100% - 300px);
        }
      }
    }

    p, label {
      font-size: .85rem;
      line-height: 1.1rem;
      margin: 0;
    }

    input, .select-selected {
      min-height: 41px;
    }

    .select-selected {
      font-size: 1rem;
      line-height: 34px;
    }

    .select-items {
      color: #fff;
      background: $blue;
      max-height: 103px;
      overflow: auto;

      span {
        padding: .55rem .7rem;
        color: #fff;

        &.same-as-selected {
          background: $orange;

        }
      }
    }
  }

  &#new-broker {
    .modal-content {
      width: 100%;
      max-width: 1100px;
      padding: 1rem;
    }

    p {
      font-size: .85rem;
      margin: 0;
    }

    input {
      min-height: 42px;

      &.btn {
        margin-right: 1rem;
        padding: .7rem 1.5rem;
        font-size: 1rem;
      }
    }

    .headline {
      padding: 1rem;
      width: 100%;
    }

    .flex .flex h3 {
      width: 100%;
      padding: 0 1rem;
    }

    .select-selected {
      height: 42px;
      line-height: 35px;
    }
  }

  &-content {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .3);
    background: #fff;
    position: relative;
    padding: 2rem;

    .icon-clear {
      position: absolute;
      z-index: 1;
      color: $orange;
      font-weight: bold;
      font-size: 1.4rem;
      transition: all .3s;
      cursor: pointer;
      right: 1rem;
      top: 1rem;

      &:hover {
        color: $blue;
      }
    }

    .btn {
      background: $orange;
      border: 2px solid $orange;

      &:hover, &:focus {
        background: #fff;
        color: $orange;
      }

      &.btn-blue {
        background: $blue;
        border: 2px solid $blue;

        &:hover, &:focus {
          background: #fff;
          color: $blue;
        }
      }
    }
  }
}

.fixed {
  position: fixed;
  left: 0;
  right: 0;
}

.flatpickr-wrapper {
  position: relative;
}

.flatpickr-calendar {
  font-size: 13px;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  padding: 3px;
  max-height: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .25);
  z-index: 0;
  overflow: hidden;
  -webkit-transition: opacity 550ms cubic-bezier(.075, .82, .165, 1);
  transition: opacity 550ms cubic-bezier(.075, .82, .165, 1);
  -webkit-transition-timing-function: cubic-bezier(.075, .82, .165, 1);
  transition-timing-function: cubic-bezier(.075, .82, .165, 1);

  table {
    td {
      padding: 0;

      &:first-child {
        padding: 0;
      }
    }

    tr {
      border: none;
    }
  }
}

.flatpickr-months {
  color: $orange;
  padding: 3px 2px 2px;
  text-align: center;
  font-size: .95rem;
  font-weight: bold;
}

.flatpickr-prev-month {
  float: left;
}

.flatpickr-next-month {
  float: right;
}

.flatpickr-prev-month, .flatpickr-next-month {
  text-decoration: none;
  padding: 0 .4em;
  cursor: pointer;
}

.flatpickr-current-month {
  padding: 0 .5em;
  font-weight: 300;
}

.flatpickr-calendar table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;

  th:nth-child(2) {
    min-width: 1px;
  }
}

.open .flatpickr-calendar {
  max-height: 302px;
  z-index: 100;
  opacity: 1;
}

.flatpickr-day {
  border: 1px solid transparent;
  display: block;
  height: 32px;
  line-height: 32px;
  padding: 0;
  text-align: center;
  transition: all .3s;
  width: 32px;
  margin: 1px;
  cursor: pointer;

  &:hover {
    color: #fff;
    background: $orange;
  }
}
