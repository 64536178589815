.uk-container-small {
  padding: 0;
}

.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: red;
}

.uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: red;
}

.uk-svg {
  transform: translate(0);
}

.uk-svg.germany-map {
  overflow: visible !important;
}

.uk-svg.germany-map path[uk-toggle] {
  transition: all 0.18s ease-in-out;
}

.uk-text-primary.pin svg path {
  fill: #0096c8;
  stroke: #0096c8 !important;
}

.uk-text-primary.pin svg circle {
  fill: #fff;
  stroke: #fff !important;
}

.pin svg path {
  fill: #ff6f00;
  stroke: #ff6f00 !important;
}

.pin svg circle {
  fill: #fff;
  stroke: #fff !important;
}

.uk-background-success {
  background-color: #7aba48;
  color: #fff !important;
}

.uk-background-warning {
  background-color: #ff6f00;
  color: #fff !important;
}

.dropdown-left {
  left: auto !important;
}

.uk-accordion.without-plus > :nth-child(n + 2) {
  margin-top: 10px;
}

.uk-accordion.without-plus .uk-accordion-title:before {
  display: none;
}

.uk-accordion.without-plus .uk-open .uk-accordion-title h2 {
  padding-left: 1rem;
  transition: all 0.18s ease-out;
}

.uk-accordion.without-plus .uk-open .uk-accordion-title svg {
  transform: rotate(90deg);
  transition: all 0.18s ease-out;
}

.uk-accordion.without-plus.arrow-180 > :nth-child(n + 2) {
  margin-top: 10px;
}

.uk-accordion.without-plus.arrow-180 .uk-accordion-title:before {
  display: none;
}

.uk-accordion.without-plus.arrow-180 .uk-accordion-title svg {
  transform: rotate(-90deg);
  transition: all 0.18s ease-out;
}

.uk-accordion.without-plus.arrow-180 .uk-open .uk-accordion-title svg {
  transform: rotate(0);
  transition: all 0.18s ease-out;
}

.uk-accordion:not(.uk-open) h2,
.uk-accordion:not(.uk-open) svg {
  transition: all 0.18s ease-out;
}

.uk-icon {
  background: url(../img/pin.svg) center no-repeat;
  background-size: 15px;
  width: 15px;
  height: 25px;
}

.uk-container a:before {
  display: none;
}

.uk-section {
  padding: 0;
}

.uk-position-absolute {
  margin-left: 0.5rem;
  margin-top: 0.6rem;
}

.uk-card-width {
  width: 100%;
}

.uk-dropdown h4 {
  margin-top: 0;
  font-size: 17px;
  font-weight: 500;
}

.uk-dropdown address {
  font-style: normal;
}

.uk-dropdown address {
  display: block;
  margin: 0.2rem 0;
}

.uk-dropdown .uk-border-rounded.uk-padding-small {
  padding: 1.2rem 2rem 0.8rem 1.2rem;
}

.uk-dropdown .uk-flex.uk-flex-between {
  padding: 0 1.2rem 1.2rem;
}

.uk-dropdown .uk-flex.uk-flex-between .uk-button {
  width: calc(50% - 0.3rem);
  border: 2px solid #0096c8;
  text-transform: none;
  font-weight: 500;
  line-height: inherit;
  font-size: 15px;
  padding: 0.4rem 1.2rem;
  min-width: 130px;
}

.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-background-success,
.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-button-success {
  background: #0096c8;
  margin-right: 0.3rem;
  color: #fff;
}
.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-background-success:hover,
.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-button-success:hover {
  background: #fff;
  margin-right: 0.3rem;
  color: #0096c8 !important;
}

.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-background-warning {
  border: 2px solid #0096c8;
  background: #fff;
  color: #0096c8 !important;
  margin-left: 0.3rem;
}
.uk-dropdown .uk-flex.uk-flex-between .uk-button.uk-background-warning:hover {
  border: 2px solid #0096c8;
  background: #0096c8;
  color: #fff !important;
}

.card-title {
  margin-top: 0;
  font-size: 17px;
  font-weight: 500;
}
.card-title-big {
  margin-top: 0;
  font-size: 20px;
  font-weight: 500;
}
